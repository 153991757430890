import React from 'react';
// 说明文档：https://github.com/jamiebuilds/react-loadable
import Loadable from 'react-loadable';
import {Spin} from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import styles from './Loading.less';


export function FullPageLoading(props) {
    return (
        <div className={styles.fullPageLoading}>
            {props.icon ? props.icon : <Spin size="small" />}
            {typeof props.text === 'string' ? <p className={styles.text}>{props.text}</p> : null}
            {React.isValidElement(props.text) ? props.text : null}
        </div>
    );
}


export function FullWrapperLoading(props) {
    return (
        <div className={styles.fullWrapperLoading}>
            {props.icon ? props.icon : <Spin size="small" />}
            {typeof props.text === 'string' ? <p className={styles.text}>{props.text}</p> : null}
            {React.isValidElement(props.text) ? props.text : null}
        </div>
    );
}

export function Loading(props) {
    if (props.error || props.timedOut) {
        console.log('Loading Error', props);
        return (
            <FullWrapperLoading
                icon={<QuestionCircleFilled />}
                text={(
                    <p>
                        请求失败
                        <a onClick={props.retry}>请重试</a>
                    </p>
                )}
            />
        );
    }
    if (props.pastDelay) {
        return <div style={{height: 200, position: 'relative'}}><FullWrapperLoading /></div>;
    }

    return null;
}

export default function (loader) {
    return Loadable({
        loader,
        loading: Loading,
    });
}
