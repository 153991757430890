import axios from "common/axios";
import {baseUrl} from 'common/constant';

// 注册
export const fetchRegister = data => {
    return axios({
        url: `${baseUrl}/net/disk/user/create`,
        method: 'post',
        params: data,
    })
}

// 登录
export const fetchLogin = data => {
    return axios({
        url: `${baseUrl}/net/disk/user/login`,
        method: 'post',
        params: data,
    })
}