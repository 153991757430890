import moment from "moment";
import * as XLSX from 'xlsx'
import {v1} from 'uuid';

export function setStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function getStorage(key) {
    return JSON.parse(localStorage.getItem(key));
}

export function timeFormat(time = new Date().getTime(), format = 'YYYY-MM-DD HH:mm:ss') {
    return moment(time).format(format);
}

export function fn() {}

export function getId() {
    return v1().replace(/-/g, '');
}

// 卡密规则
export function randKhString(len, rules) {
    let chars = '';
    if (rules.includes(1)) { // 数字
        chars += '0123456789';
    }
    if (rules.includes(2)) { // 小写
        chars += 'abcdefhijkmnprstwxyz';
    }
    if (rules.includes(3)) { // 大写
        chars += 'ABCDEFGHJKMNPQRSTWXYZ';
    }
    let maxPos = chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

/**
 * 生成卡密
 * @param rules Object 规则
 * @param startStr String 开头
 * @param endStr String 结尾
 * @param len Number 长度
 * @param num Number 数量
 * */
export function toolCreateCode(rules = [], startStr = '', endStr = '', len = 6, num = 0) {
    let codes = '';
    for (let i = 0; i < num; i++) {
        codes += startStr + randKhString(len, rules) + endStr + (i + 1 === num ? '' : ',');
    }
    return Array.from(new Set(codes.split(',')));
}

/**
 * 导出
 * */
export function toolDownload(json,fileName){
    const type = 'xlsx'//定义导出文件的格式
    let tmpDown;//导出的内容
    let tmpData = json[0];
    json.unshift({});
    let keyMap = []; //获取keys
    for (let k in tmpData) {
        keyMap.push(k);
        json[0][k] = k;
    }
    let jsonData = [];//用来保存转换好的json

    json.map((v, i) => keyMap.map((k, j) => Object.assign({}, {
        v: v[k],
        position: (j > 25 ? getCharCol(j) : String.fromCharCode(65 + j)) + (i + 1)
    }))).reduce((prev, next) => prev.concat(next)).forEach((v, i) => jsonData[v.position] = {
        v: v.v
    });
    let outputPos = Object.keys(jsonData); //设置区域,比如表格从A1到D10
    let tmpWB = {
        SheetNames: ['mySheet'], //保存的表标题
        Sheets: {
            'mySheet': Object.assign({},
                jsonData, //内容
                {
                    '!ref': outputPos[0] + ':' + outputPos[outputPos.length - 1] //设置填充区域
                })
        }
    };
    tmpDown = new Blob([s2ab(XLSX.write(tmpWB,
        {bookType: (type || 'xlsx'),bookSST: false, type: 'binary'}//这里的数据是用来定义导出的格式类型
    ))], {
        type: ""
    }); //创建二进制对象写入转换好的字节流
    saveAs(tmpDown,fileName);
}

function saveAs(obj, fileName){//导出功能实现
    let tmpa = document.createElement("a");
    tmpa.download = fileName || "下载";
    tmpa.href = URL.createObjectURL(obj); //绑定a标签
    tmpa.click(); //模拟点击实现下载
    setTimeout(function () { //延时释放
        URL.revokeObjectURL(obj); //用URL.revokeObjectURL()来释放这个object URL
    }, 100);
}

function s2ab(s){ //字符串转字符流
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

function getCharCol(n){
    let temCol = '',
        s = '',
        m = 0
    while (n > 0) {
        m = n % 26 + 1
        s = String.fromCharCode(m + 64) + s
        n = (n - m) / 26
    }
    return s
}



