import React from "react";
import {Modal} from 'antd';

export default function UserServer(props) {
    const {visible, onCancel} = props;

    return (
        <Modal
            width={800}
            title=''
            visible={visible}
            onCancel={onCancel}
            footer={null}
            closable={false}
        >
            <h3 className='text-center'>速达智盘用户服务协议</h3>
            <div className='f12'>
                <h4>服务协议</h4>
                <div className='mb10'>
                    本服务条款（下称“服务条款”）是您与速达智盘之间关于您（“您”或“用户”）访问和使用本网站以及速达云笔记提供的其他服务（下称“服务”）的主要协议。
                </div>
                <div className='mb10'>
                    在接受本协议之前，请您仔细阅读全部内容，如您无法准确理解或不同意本协议的任一内容，
                    请不要进行后续操作。您通过网络页面点击确认、实际购买或使用速达智盘所提供的服务等操作均表示您已阅读并充分理解本协议之内容，与速达云笔记就使用或订购相应产品和/或服务已达成合意，
                    并同意接受本协议的全部约定内容，受其约束。
                </div>
                <div className='mb10'>
                    由于互联网行业高速发展，本服务条款并不能完整罗列并覆盖您与速达智盘的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，您同意速达云笔记有权不时地对本服务条款做任何修改和补充，并予以公示。通常情况下（例如当需要进行修改或补充以满足适用法律要求时），对服务条款、隐私政策或规则的修改将在公布时或规定的生效日立即生效。您继续访问和使用本网站即视为您接受修订后的服务条款。
                    否则，您有权通过停止访问本网站且拒绝使用服务、删除您在本网站上的信息和帐户等方式来终止服务条款。
                </div>
                <div className='mb10'>
                    速达智盘提供客服协作咨询指导、图片上传、跳转第三方链接、关联第三方云盘等各项服务。
                    用户在本网站平台所创建的独创性数据归属该用户所有，该用户有权对前述数据进行任何形式的处置，包括从平台中复制，导出和删除。
                </div>
                <div className='mb10'>
                    用户应当确保其公开或存储（方式包括但不限于：上传、保存、公布、发布等）于本网站的内容不存在任何侵犯其他第三方知识产权的情形。若存在本条所述情形，速达云笔记有权根据具体情节针对上述内容，采取包括但不限于限制、屏蔽、删除、修改等手段，
                    由此给网站用户造成的损失由网站用户自行承担；若速达智盘因网站用户存在本条所述行为而向其他第三方承担法律责任的，速达云笔记有权向网站用户追偿全部损失。
                </div>
                <h4>网站使用规则</h4>
                <div  className='mb10'>用户在使用本网站服务过程中，必须遵循以下规则：</div>
                <div  className='mb10'>遵守中国有关的法律法规； 不得对本网站服务进行任何形式的对其他第三方的再授权使用、销售或转让；
                    不得为设计开发竞争产品对本网站进行任何形式的反向工程、反向编译、反汇编，或在竞争产品抄袭模仿本网站的设计；
                    不得对本网站的连续服务和商誉构成损害的其他行为，包括对网站服务器的攻击； 遵守本协议中的全部约定。
                    如用户在使用本网站服务时违反任何上述规定，速达智盘有权要求用户改正或直接采取一切必要的措施
                    （包括但不限于暂停或终止用户使用本网站服务的权利并追讨因此带来的损失）以减轻用户不当行为造成的影响。</div>
                <div  className='mb10'>您应保证对于您通过速达智盘保存、分享或使用的该等内容，您为所有权人或已取得合法授权，并且该等内容不会违反任何适用的法律法规、 也不侵犯任何第三方的合法权益。如果第三方提出异议，速达云笔记有权根据独立判断删除相关的内容，且有权追究您的法律责任。 如因此给速达云笔记或任何第三方造成损失的，您应承担全部的损害赔偿责任。</div>
                <div className='mb10'>用户须对在本网站的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用注册帐户导致其他用户误认；否则本网站有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。</div>
                <div className='mb10'>用户承诺不得以任何方式利用本网站直接或间接从事违反中国法律、以及社会公德的行为， 本网站有权对违反上述承诺的内容予以删除，针对违反法律法规的行为，本网站将上报用户数据至相关执法部门。</div>
                <div className='mb10'>用户不得利用本网站服务制作、上载、复制、发布、传播或者转载如下内容：</div>
                <div className='mb10'>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； 损害国家荣誉和利益的； 煽动民族仇恨、民族歧视，破坏民族团结的； 破坏国家宗教政策，宣扬邪教和封建迷信的； 散布谣言，扰乱社会秩序，破坏社会稳定的； 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的； 侮辱或者诽谤他人，侵害他人合法权益的； 含有法律、行政法规禁止的其他内容的信息。
                    本网站有权对用户使用本网站的情况进行审查和监督，如用户在使用本网站时违反任何上述规定， 本网站或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用本网站的权利）以减轻用户不当行为造成的影响。</div>
                <div className='mb10'>本网站有权但无义务对用户发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规对侵权信息进行处理。</div>
                <h4>免责申明</h4>
                <div className='mb10'>本网站将尽最大努力保障软件平台的连续可靠运行，但鉴于网络服务的特殊性，本网站对可能发生的服务的中断或终止所可能导致的一切损失，不承担任何法律责任及经济赔偿。</div>
                <div className='mb10'>本网站将尽可能采取一切措施保护用户数据及个人信息资料安全。但用户通过使用或安装第三方应用打开本网站中的文件，该文件会在第三方应用中打开，由于第三方应用而造成的损失，本网站不承担任何法律责任及经济赔偿。</div>
                <div className='mb10'>鉴于互联网体制及环境的特殊性，客户在服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途，本网站对此不承担任何责任。</div>
                <div className='mb10'>客户在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）及匿名或冒名的信息的风险本网站对此不承担任何责任。</div>
                <h4>违约责任</h4>
                <div>由于用户通过分享之信息、使用本服务其他功能、违反本合约、或侵害他人任何权利因而衍生或导致任何第三人向本网站及其关联公司提出任何索赔或请求，或本网站及其关联公司因此而发生任何损失，用户同意将足额进行赔偿（包括但不限于合理的律师费）。</div>
            </div>
        </Modal>
    )
}