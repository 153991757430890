const env = process.env.REACT_APP_SECRET_ENV || 'localhost';

export const baseUrl = '/robot/data'
export const cloudUrl = '/cloud/data'
export const commonUrl = '/common/data'

export const uploadUrl = `${baseUrl}/net/disk/common/qiniu/upload`;

// 路由
export const Base = `/`;
export const urlDisk = `${Base}disk`; // 我的网盘
export const urlNewsOld = `${Base}newsOld`; // 消息监控
export const urlNews = `${Base}news`; // 消息监控
export const urlCarmineList = `${Base}carmine`; // 卡密管理
export const urlGroup = `${Base}group`; // 群组监控
export const urlUserList = `${Base}user`; // 网盘好友
export const urlTool = `${Base}tool`; // 系统工具
export const urlRecharge = `${Base}recharge`; // 充值中心
export const urlCourse = `${Base}course`; // 使用教程
export const urlPathList = `${Base}path/list`; // 目录显示
export const urlPathSet = `${Base}path/set`; // 目录设置
export const urlPathCache = `${Base}path/cache`; // 目录缓存文件
export const urlUser = `${Base}admin/user`; // 管理员-用户列表
export const urlAdminTask = `${Base}admin/task`; // 管理员-任务列表
export const urlCode = `${Base}admin/code`; // 管理员-卡密列表

// 目录链接
export const pathUrl = {
    'development': 'http://localhost:3006/',
    'production': 'http://client.dirts.cn/',
    'localhost': 'http://localhost:1220/',
}[env]

export const menus = [
    {
        id: 1,
        label: '我的应用',
        children: [{id: '1-1', label: '我的网盘', url: urlDisk},{id: '1-2', label: '账号激活', url: urlRecharge},]
    },
    /* {
        id: 2,
        label: '网盘机器人',
        children: [
            {id: '2-1', label: '机器人高级版', url: urlNews},
            {id: '2-2', label: '机器人精简版', url: urlNewsOld},
            {id: '2-4', label: '卡密管理', url: urlCarmineList},
        ]
    }, */
    {
        id: 3,
        label: '网盘管理',
        children: [
            {id: '3-1', label: '好友列表', url: urlUserList},
            // {id: '3-2', label: '群组列表', url: urlUserList},
            // {id: '3-3', label: '文件列表', url: urlUserList},
        ]
    },
    {
        id: 4,
        label: '列表管理',
        children: [
            {id: '4-1', label: '文件列表', url: urlPathList},
            {id: '4-2', label: '列表设置', url: urlPathSet},
            // {id: '4-3', label: '缓存文件', url: urlPathCache},
        ]
    },
    {
        id: 99,
        label: '系统管理',
        children: [
            {id: '99-1', label: '用户列表', url: urlUser},
            {id: '99-2', label: '任务列表', url: urlAdminTask},
            {id: '99-3', label: '系统卡密', url: urlCode},
            {id: '2-3', label: '群文件监控', url: urlGroup},
        ]
    }
];

export const PathType = {
    1: '网盘文件',
    // 2: '扩容网盘',
    // 3: '群组文件',
    // 4: '好友文件',
}

export const PathTypeList = Object.keys(PathType).map(item => ({
    id: Number(item),
    name: PathType[item],
}))


export const CodeType = {
    1: '自动发货',
    // 2: '群组监控',
    3: '在线文档'
}
export const CodeTypeList = Object.keys(CodeType).map(item => ({
    id: Number(item),
    name: CodeType[item],
}))
