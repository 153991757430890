import axios from 'axios';
import qs from 'qs';
import {message} from 'antd';

const config = {
    method: 'get',
    // 基础url前缀
    // baseURL: 'http://server.sdzlk.cn/',
    // baseURL: 'https://mockapi.eolinker.com/GvIvyuH0dc544a3670be79eeebaa1d4d53b3691d0a41d6d/',
    // 请求头信息
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    // 参数
    data: {},
    // 设置超时时间
    timeout: 10000,
    // 携带凭证
    withCredentials: false,
    // 返回数据类型
    responseType: 'json'
}

export default function Axios (options) {
    return new Promise((resolve, reject) => {
        const instance = axios.create({
            baseURL: config.baseURL,
            headers:Object.assign(config.headers, options.headers || {}),
            transformResponse: [function (data) {}]
        });

        instance.interceptors.request.use(event => {
            // 请求头带上Token
            const token = localStorage.getItem('token');
            if (token && !('cookies' in event.headers) && !('Cookies' in event.headers)) {
                event.headers.Cookies = token
            }
            // 根据请求方法，序列化传来的参数，根据后端需求是否序列化
            if (['post', 'put', 'delete'].includes(event.method.toLocaleLowerCase())) {
                event.data = qs.stringify(event.data);
            } else {
                event.params = event.params || event.data;
            }
            return event;
        }, error => {
            return Promise.reject(error);
        });

        instance.interceptors.response.use(response => {
            let data = {};
            // IE 9
            if(response.data === undefined){
                data = response.request.responseText
            } else{
                data = response.data
            }
            data = JSON.parse(data);
            if (!data.success && data.code === 10) {
                message.error('未登录，功能不可用').then();
                localStorage.setItem('user', undefined);
                /* setTimeout(() => {
                    window.location.replace('/login')
                }, 500) */
                return;
            }
            if (!data.success) {
                message.destroy();
                message.error(data.msg).then();
            }
            return data;
        }, error => {
            return Promise.reject(error)
        });

        //请求处理
        instance(options).then((res) => {
            resolve(res)
            return false
        }).catch((error) => {
            reject(error)
        })
    })
}
