import React, {useMemo} from "react";
import { Layout, Menu, Modal } from 'antd';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { useSetState, useRequest, useMount } from 'ahooks';
import {Image} from 'antd';
import {menus, urlUser, urlCode} from 'common/constant';
import {setStorage} from 'common/util';
import Login from 'components/Login';
import css from './index.less';
import logo from 'common/image/logo.png'
import {fetchUserInfo} from './api';

const { Header, Sider, Content, Footer } = Layout;

const authRouters = (r => r.keys().map(key => r(key)))(
    require.context('../../', true, /routers\.js$/)
).map(i => i.default);

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

function App() {
    const navigate = useNavigate();
    const [state, setState] = useSetState({
        info: {}, // 用户信息
        openKeys: ['1'],
        active: '1-1', // 菜单
        visible: true,
    });



    useMount(() => {
        const {pathname} = window.location;
        const {id = '1-1'} = menus.map(i => i.children).flat().find(item => pathname.indexOf(item.url) > -1) || {};
        setState({active: id, openKeys: [id.split('-')[0]]});
    })

    const {data = {}} = useRequest(fetchUserInfo);
    const result = data.result || {};

    // 退出登录
    const handleLoginOut = () => {
        setStorage('token', undefined)
        navigate('/login');
    }

    const items = useMemo(() => {
        const fn = event => {
            if (!event?.length) return null;
            return event.map(item => result.type !==1 && item.id === 99 ? null : getItem(item.url ? <Link to={item.url}>{item.label}</Link> : item.label, item.id, null, fn(item.children)))
        }
        return fn(menus);
        }, [menus, result.type]);

    return (
        <div className={css.css}>
            <>
                <Layout className='layout'>
                    <Header className='header d-flex mb10 color-white'>
                        <Image style={{borderRadius: 8}} src={logo} width={40} height={40} preview={false} />
                        <span className='ml10'>速达智盘</span>
                        {result.account ? (
                            <div className='flex-1 text-right'>
                                <span>账号：{result.account}</span>
                                <span onClick={handleLoginOut} className='ml15 link'>退出登录</span>
                            </div>
                        ) : (
                            <div className='flex-1 text-right'>
                                <span onClick={(() => navigate('/login'))} className='ml15 link'>去登录</span>
                            </div>
                        )}
                    </Header>
                    <Layout className='layout-content'>
                        <Sider className='slider'>
                            <Menu
                                onSelect={e => {setState({active: e.key})}}
                                onOpenChange={e => {setState({openKeys: e})}}
                                selectedKeys={[state.active]}
                                openKeys={state.openKeys}
                                mode="inline"
                                items={items}
                            />
                            {/*{menus.map(item => (
                                <Link key={item.url} to={item.url}>
                                    <div
                                        key={item.id}
                                        onClick={() => {setState({active: item.id})}}
                                        className={`slider-item ${state.active === item.id ? 'active' : ''}`}
                                    >
                                        {item.title}
                                    </div>
                                </Link>
                            ))}
                            {result.type === 1 && (
                                <>
                                    <Link to={urlUser}>
                                        <div
                                            onClick={() => {setState({active: 99})}}
                                            className={`slider-item ${state.active === 99 ? 'active' : ''}`}
                                        >
                                            用户列表
                                        </div>
                                    </Link>
                                    <Link to={urlCode}>
                                        <div
                                            onClick={() => {setState({active: 89})}}
                                            className={`slider-item ${state.active === 89 ? 'active' : ''}`}
                                        >
                                            卡密列表
                                        </div>
                                    </Link>
                                </>
                            )}*/}
                        </Sider>
                        <Content className='content'>
                            <Routes>
                                {authRouters}
                            </Routes>
                        </Content>
                    </Layout>
                    <Footer className='footer'>
                        <div className='text-center f12'>
                            Copyright ©2023 速达智盘 客服微信：speedyschool
                            <img src={require('./image/beian.png')} />
                            <a target='_blank' href="http://beian.miit.gov.cn">备案号：浙ICP备2021029045号-2</a>
                        </div>
                    </Footer>
                </Layout>
            </>
             <Modal
                title='公告'
                visible={state.visible}
                width={500}
                onOk={() => setState({visible:  false})}
                onCancel={() => setState({visible:  false})}
                footer={null}
            >
                 <div className='mt8 color-red'>
                     一、任何使用本平台的用户不得发布、传送、传播违反国家法律法规禁止的内容,以及侵害他人合法权利的内容等。另外，本软件不存储资料内容，也不能识别网盘资源是否合规，一切因此而产生的法律问题由用户自己承担，与本平台无关
                 </div>
                 <div className='mt8 color-red'>
                     二、本平台只为解决大家的需求，节省劳动时间、提高工作效率，并无任何主观侵犯他人权利或利益的意图。
                 </div>
                 <div className='mt8' />
                 <h4 > 您必须：</h4>
                 <ol>
                     <li>认真阅读并遵守本服务条款及其他规则。</li>
                     <li>自行准备设备，包括与本服务有关的终端设备（如电脑或移动终端设备）和必要的网络接入设备等。</li>
                     <li>承担个人上网和与本服务有关的费用。</li>
                     <li>遵守中华人民共和国相关法律法规（如果用户是中华人民共和国境外的使用者，还应同时遵守其所在国家或地区的法律法规）。</li>
                 </ol>
                 <h4>用户使用规则</h4>
                 <ol>
                     <li>速达智盘仅供您自行使用，不得销售、转让、出租、共享、许可或以其它方式提供给任何第三方使用</li>
                     <li>您应保证对于您通过速达智盘保存、分享或使用的该等内容，您为所有权人或已取得合法授权，并且该等内容不会违反任何适用的法律法规、
                         也不侵犯任何第三方的合法权益。如果第三方提出异议，速达智盘有权根据独立判断删除相关的内容，且有权追究您的法律责任。
                         如因此给速达智盘或任何第三方造成损失的，您应承担全部的损害赔偿责任。</li>
                 </ol>
                 <div className='mt8'>注：使用本站即表示同意以上规则，如您不能接受请勿使用，否则后果自负。如果您对本规则有任何意见或建议，或您对本服务条款内容有任何疑问，可发邮件至459471667@qq.com</div>
             </Modal>
            {/* <Modal
                title='公告'
                visible={state.visible}
                width={500}
                onOk={() => setState({visible:  false})}
                onCancel={() => setState({visible:  false})}
            >
                <h4>网盘目录系统新版已上线</h4>
                <div>目录系统的用户后续使用请到新版上，</div>
                <div>在新版系统注册后，可以联系管理员将有效期转移到新版目录系统</div>
                <div>管理员微信：speedyschool</div>
                <div className='text-link' onClick={() => window.open('https://admin.dirts.cn', '_blank')}>新版后台地址：https://admin.dirts.cn</div>
                <div className='text-link' onClick={() => window.open('https://path.dirts.cn/test', '_blank')}>新版前台演示：https://path.dirts.cn/test</div>
            </Modal> */}
        </div>
    );
}

function Browser() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/login'} element={<Login />} />
                <Route path={'/*'} element={<App />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Browser;
