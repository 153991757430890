import React from "react";
import { useBoolean } from 'ahooks';
import {Input, Image, Form, Button, message} from 'antd';
import {setStorage} from 'common/util';
import UserServer from 'components/UserServer'
import css from './index.less';
import {fetchRegister, fetchLogin} from './api';
import {useNavigate} from "react-router-dom";

export default function Index() {
    const navigate = useNavigate();
    const [register, {toggle}] = useBoolean(false);

    const [visible, setVisible] = React.useState(false);

    const handleSubmit = (values) => {
        const fn = register ? fetchRegister : fetchLogin;
        !register && message.loading('登录中');
        fn(values).then(res => {
            if (!res.success) return;
            message.destroy();
            if (register) {
                message.success('注册成功').then();
                toggle();
                return;
            }
            setStorage('token', res.result.token);
            navigate('/disk');
        })
    }

    return (
        <div className={css.css}>
            <Form
                onFinish={handleSubmit}
            >
                <div className='login-box'>
                    <div className='flex-1 '>
                        <div className='right-content'>
                            <div className='d-flex align-center'>
                                <Image style={{borderRadius: 10}} width={40} src={require('./logo.png')} preview={false} />
                                <div className='f20 ml10'>速达智盘</div>
                            </div>
                            <div className='mt20 f20'>一款便捷的文件笔记管理系统</div>
                        </div>

                    </div>
                    <div className='flex-1 left-content'>
                        <div className='f20 text-center mb20'>{register ? '用户注册' : '欢迎登录'}</div>
                        <div>
                            <div className='color-gray f16 mb10'>账号</div>
                            <Form.Item
                                name='account'
                                rules={[{ required: true, message: '请输入账号' }, {pattern: /^[0-9]*$/, message: '账号为数字'}]}
                            >
                                <Input className='login-input' />
                            </Form.Item>
                            <div className='color-gray f16 mb10'>密码</div>
                            <Form.Item
                                name='password'
                                rules={[{ required: true, message: '请输入密码' }]}
                            >
                                <Input.Password className='login-input' />
                            </Form.Item>
                        </div>
                        <div className='mt10'>
                            <Button className='login-ant-btn' type='primary' htmlType="submit">
                                {register ? '立即注册' : '立即登录'}
                            </Button>
                        </div>
                        <div className='mt20'>
                            <span className='text-link f16'>
                                {register ? '已有账号？去登录' : '还没有账号？去注册'}
                            </span>
                        </div>
                        <div className='f12 text-center mt10'>
                            <span className='color-gray'>登录即表示您已阅读并同意</span>
                            <span onClick={() => setVisible(true)} className='text-link'>《速达智盘用户服务协议》</span>
                        </div>
                        <UserServer visible={visible} onCancel={() => setVisible(false)} />
                    </div>
                </div>
            </Form>
        </div>
    )
}
