import React from 'react';
import {Route} from 'react-router-dom';
import Load from 'components/Loading';
import {Base, urlDisk, urlNews, urlCarmineList, urlNewsOld, urlGroup, urlUserList, urlTool, urlRecharge,
    urlCourse, urlUser, urlCode, urlPathList, urlPathSet, urlPathCache, urlAdminTask} from 'common/constant';

const LoadableDisk = Load(() => import(/* webpackChunkName: "Disk" */ './containers/Disk'));
const LoadableNews = Load(() => import(/* webpackChunkName: "News" */ './containers/News'));
const LoadableCarmine = Load(() => import(/* webpackChunkName: "Carmine" */ './containers/Carmine'));
const LoadableNewsOld = Load(() => import(/* webpackChunkName: "NewsOld" */ './containers/NewsOld'));
const LoadableGroup = Load(() => import(/* webpackChunkName: "Group" */ './containers/Group'));
const LoadableUserList = Load(() => import(/* webpackChunkName: "UserList" */ './containers/User'));
const LoadableTool = Load(() => import(/* webpackChunkName: "Tool" */ './containers/Tool'));
const LoadableRecharge = Load(() => import(/* webpackChunkName: "Recharge" */ './containers/Recharge'));
const LoadableCourse = Load(() => import(/* webpackChunkName: "Course" */ './containers/Course'));
const LoadableAdmin = Load(() => import(/* webpackChunkName: "Admin" */ './containers/Admin'));
const LoadableCode = Load(() => import(/* webpackChunkName: "Code" */ './containers/Code'));
// const LoadablePath = Load(() => import(/* webpackChunkName: "Path" */ './containers/Path'));
// 网盘目录
const LoadablePathSet = Load(() => import(/* webpackChunkName: "PathSet" */ './containers/Path/Set'));
const LoadablePathCache = Load(() => import(/* webpackChunkName: "PathCache" */ './containers/Path/Cache'));
const LoadablePathList = Load(() => import(/* webpackChunkName: "PathList" */ './containers/Path/List'));
// 管理员
const LoadableAdminTaskList = Load(() => import(/* webpackChunkName: "Admin-TaskList" */ './containers/Admin/TaskList'));


const routers = [
    <Route key={Base} path={Base} element={<LoadableDisk />} />,
    <Route key={urlDisk} path={urlDisk} element={<LoadableDisk />} />,
    <Route key={urlNews} path={urlNews} element={<LoadableNews />} />,
    <Route key={urlCarmineList} path={urlCarmineList} element={<LoadableCarmine />} />,
    <Route key={urlNewsOld} path={urlNewsOld} element={<LoadableNewsOld />} />,
    <Route key={urlGroup} path={urlGroup} element={<LoadableGroup />} />,
    <Route key={urlUserList} path={urlUserList} element={<LoadableUserList />} />,
    <Route key={urlTool} path={urlTool} element={<LoadableTool />} />,
    <Route key={urlRecharge} path={urlRecharge} element={<LoadableRecharge />} />,
    <Route key={urlCourse} path={urlCourse} element={<LoadableCourse />} />,
    <Route key={urlUser} path={urlUser} element={<LoadableAdmin />} />,
    <Route key={urlCode} path={urlCode} element={<LoadableCode />} />,
    // 网盘目录
    <Route key={urlPathList} path={urlPathList} element={<LoadablePathList />} />,
    <Route key={urlPathSet} path={urlPathSet} element={<LoadablePathSet />} />,
    <Route key={urlPathCache} path={urlPathCache} element={<LoadablePathCache />} />,
    // 管理员
    <Route key={urlAdminTask} path={urlAdminTask} element={<LoadableAdminTaskList />} />,
];

export default routers;
